import NavButton from "@/components/atoms/NavButton.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useFormModalStore } from "@/store/modals/formModalStore.ts";
import { useNavStore } from "@/store/navStore.ts";
import IconMenu2 from "@/components/icons/IconMenu2.tsx";
import { trackEvent } from "@/lib/utils/tracking";

const Nav = ({ url }: any) => {
  const openFormModal = useFormModalStore((state) => state.open);
  const toggleMobileMenu = useNavStore((state) => state.toggle);

  return (
    <nav
      className={
        "flex flex-row flex-nowrap justify-between align-middle max-w-7xl mx-auto p-4"
      }
    >
      <div className={"my-auto"}>
        <a href={"/"} title={"Mobilabonnement.no"}>
          <img
            className={"w-56"}
            alt={"Gå til Mobilabonnement.no"}
            src={"/logo_dark.png"}
            width={222}
            height={18}
          />
        </a>
      </div>
      <div className={"hidden gap-2 md:block"}>
        <NavButton title={"Hjem"} href={"/"} currentPathname={url.pathname} />
        <NavButton
          title={"Bedrift"}
          href={"/bedrift"}
          currentPathname={url.pathname}
        />
        <NavButton
          title={"Sammenlign"}
          href={"/sammenlign"}
          currentPathname={url.pathname}
        />
        <NavButton
          title={"Om oss"}
          href={"/om-oss"}
          currentPathname={url.pathname}
        />
        <NavButton
          title={"Artikler"}
          href={"/artikler"}
          currentPathname={url.pathname}
        />
      </div>
      <div className={"flex gap-2"}>
        <Button
          onClick={() => {
            trackEvent("get_offer_clicked", {
              placement: "nav",
            });

            openFormModal();
          }}
          className={"hidden md:block"}
          aria-label={"Få tilbud"}
        >
          Få tilbud
        </Button>
        <button
          className={"md:hidden"}
          aria-label={"Mobilmeny"}
          onClick={() => toggleMobileMenu()}
        >
          <div className={"w-8 h-auto"}>
            <IconMenu2 />
          </div>
        </button>
      </div>
    </nav>
  );
};

export default Nav;
